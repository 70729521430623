export const aboutUs = {
  pageTitle: "The Road We're On",
  topSectionTitle: "Who We Are",
  topSectionBody:
    "We are a dynamic and innovative logistics consulting company that is dedicated to driving efficiency, transparency, and profitability for businesses of all sizes. In-House Logistics is part of an integrated transportation group which includes a Consulting Firm, 3PL, Asset Based Division of 200 trucks/400 trailers and a Warehousing arm. With a client-centric approach and a wealth of industry expertise, we empower you to take charge of your logistics journey.",
  processSectionsTitle: "Our Process",
  processSectionBody:
    "At In-House Logistics, we understand that each client is unique with specific needs and goals. We take the time to listen, analyze, and develop customized solutions that address the individual challenges faced by our clients. We forge strong partnerships and collaborate closely with our clients to achieve sustainable and long-term success. With multiple service offerings we have a solution that will fit your current and future needs!",

  visionMission: [
    {
      title: "Vision",
      body: "Our vision is to be the catalyst for change in the logistics industry. By pursuing excellence and shaping the future of supply chain management through transparency, innovation, collaboration, and continuous improvement, we envision a world where shippers thrive with optimized logistics strategies. ",
    },
    {
      title: "Mission",
      body: "Driven by our commitment to logistics excellence and transparency, we combine industry-leading expertise and a customer-centric approach to deliver optimal outcomes for our clients. Our mission is to be your trusted logistics partner and guide you through every step of your logistics transformational journey.",
    },
    {
      title: "Our Difference",
      body: "Unlike traditional logistics models, we believe in empowering our clients to take control of their logistics operations. Our collaborative approach ensures full transparency and efficiency while we provide expert guidance, advanced technology, and comprehensive support to facilitate your logistics success story.",
    },
  ],
};

export const faqSection = [
  {
    name: "serviceOffer",
    question: "What services does In-House Logistics offer?",
    answer:
      "We offer a comprehensive range of solutions, including an Initial Service offering, Technology implementation, Managed and Supported Services with onsite and back office support, and a full Consultative solution. Our goal is to empower your business with tailored logistics strategies and expert support to optimize your supply chain operations so you can begin turning your freight into profit!",
  },
  {
    name: "different3pl",
    question:
      "How is In-House Logistics different from traditional freight brokerages?",
    answer:
      "While In-House Logistics does have a traditional freight brokerage under the company umbrella, it is our sole focus to supply our clients with the tools and strategies needed to succeed in the marketplace without the need of freight brokerages.",
  },
  {
    name: "techSolutions",
    question: "What technology solutions do you provide through your offering?",
    answer:
      "We provide access to industry-leading Transportation Management Software. Clients can leverage these solutions for load planning, order generation, pricing visibility, real-time tracking, carrier management, reporting and more.",
  },

  {
    name: "industryExpertise",
    question: "What types of companies and industries do you serve?",
    answer:
      "We cater to a diverse set of companies and industries including manufacturing, produce, food service, retail, pharmaceuticals and more.  Our expertise and services are customizable to meet the unique requirements of any firm.",
  },

  {
    name: "security",
    question: "How do you ensure data security and confidentiality?",
    answer:
      "We prioritize data security and adhere to strict confidentiality protocols. Our team implements robust security measures to safeguard sensitive information and we maintain compliance with industry regulations and best practices.",
  },

  {
    name: "contact",
    question: "How can we get started with your company's logistics solutions?",
    answer:
      "Getting started is easy! Simply reach out to us through our website's contact form or give us a call. Our team will schedule a consultation to help better understand your needs, provide customized recommendations, and guide you through the onboarding process.",
  },
];