export const homeHeroImages = [
  {
    key: 0,
    image: "home1.webp",
    imageAlt: "Truck on Highway",
    slideId: "hwyTruck",
    name: "hwyTruck",
  },
  {
    key: 1,
    image: "trucksDocked.webp",
    imageAlt: "Docked Trucks",
    slideId: "docked",
    name: "docked",
  },
  {
    key: 2,
    image: "truckDrive1.webp",
    imageAlt: "Driving Truck",
    slideId: "driveTruck1",
    name: "driveTruck1",
  },
  {
    key: 3,
    image: "TruckRoad.webp",
    imageAlt: "Truck on the Road",
    slideId: "roadTruck",
    name: "roadTruck",
  },
];
