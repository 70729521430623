import React from "react";
import ThreeStackCard from "./ThreeStackCard";

const ThreeStack = (props) => {
  const cards = props.cards;
  const finalIndex = cards.length - 1;

  // console.log(finalIndex);
  return (
    <div className="flex flex-col lg:flex-row mx-auto px-16 justify-center lg:justify-evenly gap-y-16 gap-x-8 xl:gap-x-12">
      {cards.map((card, i) => {
        let isLast = false;
        let isFirst = false;
        if (i === 0) {
          isFirst = true;
        }
        if (i === finalIndex) {
          isLast = true;
        }
        return (
          <ThreeStackCard
            key={i}
            title={card.title}
            body={card.body}
            isFirst={isFirst}
            isLast={isLast}
          />
        );
      })}
    </div>
  );
};

export default ThreeStack;
