import React,{useState,useEffect} from 'react'

const HerouselSlide = (props) => {
    const slideImage = props.slideImage;
    const slideImgAlt = props.slideImgAlt;
    const slideId = props.slideId;
    const style = props.slideStyle;
  
    const [img, setImg] = useState();
  
    function assignImage(imgName) {
      import("../assets/" + imgName).then((img) => setImg(img.default));
    }
  
    useEffect(() => {
      assignImage(slideImage);
    });
    
    return (
      <div
        id={slideId}
        className=""
      >
        <img
          src={img}
          alt={slideImgAlt}
          className={style}
        />
      </div>
    );
}

export default HerouselSlide