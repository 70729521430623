import React, { useState, useEffect } from "react";

const SubServiceCard = (content) => {
  const [img, setImg] = useState();
  const [ifLeft, setIfLeft] = useState(true);
  const [isTruncated, setIsTruncated] = useState(false);
  const title = content.title;
  const description = content.desc;
  const image = content.image;
  const imageAlt = content.imageAlt;
  const index = content.index;

  function assignImage(imgName) {
    import("../../assets/" + imgName).then((img) => setImg(img.default));
  }

  useEffect(() => {
    assignImage(image);
    if (index % 2 === 0) {
      setIfLeft(false);
    }

    if (description.length > 400) {
      setIsTruncated(true);
    }
  }, []);

  return (
    <div className="flex flex-col md:flex-row justify-center mx-auto p-2 my-4 w-[90%] md:w-4/5 xl:w-3/5 bg-black bg-opacity-40 h-fit rounded-xl leading-relaxed shadow-sm hover:shadow-flame-pea-400 hover:shadow-md  hover:scale-110 group transition-all duration-150 group">
      <div
        className={
          ifLeft
            ? "md:order-first my-auto md:py-4 md:pr-4 p-4 md:w-2/5 h-fit"
            : "md:order-last my-auto md:py-4 md:pl-4 p-4 md:w-2/5 h-fit"
        }
      >
        <img src={img} alt={imageAlt} className=" my-auto rounded-xl"></img>
      </div>
      <div className="flex flex-col p-4 w-full md:w-3/5 m-auto text-center md:px-6">
        <h2 className="py-2 mb-2 text-2xl text-flame-pea-100  group-hover:text-flame-pea-500 transition-all duration-200 group-hover:scale-105">
          {title}
        </h2>
        <p className={isTruncated ? "line-clamp-5" : "line-clamp-none"}>
          {description}
        </p>
        {isTruncated ? (
          <button
            className="text-flame-pea-500 underline underline-offset-2"
            onClick={() => {
              setIsTruncated(false);
            }}
          >
            Read More
          </button>
        ) : null}

        {!isTruncated && description.length > 400 ? (
          <button
            className="text-flame-pea-500 underline underline-offset-2"
            onClick={() => {
              setIsTruncated(true);
            }}
          >
            Read less
          </button>
        ) : null}
      </div>
    </div>
  );
};

export default SubServiceCard;
