import React from "react";
import ReactDOM from "react-dom/client";

import App from "./App";
import "tw-elements-react/dist/css/tw-elements-react.min.css";
import "./index.css";
import { BrowserRouter } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <div className="bg-gradient-to-br from-black via-gray to-black text-flame-pea-50 scroll-smooth font-albert leading-relaxed antialiased">
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </div>
);
