import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { scrollToTop } from "../components/ScrollToTop";

const SideCard = (props) => {
  const [img, setImg] = useState();
  let cardText = props.cardText;
  let cardImg = props.cardImg;
  let titleText = props.titleText;
  let cardImgAlt = props.cardImgAlt;
  let cardHref = props.cardHref;
  let leftImg = props.leftImg;

  function assignImage(imgName) {
    import("../assets/" + imgName).then((img) => setImg(img.default));
  }

  useEffect(() => {
    assignImage(cardImg);
  });

  return (
    <div>
      <Link
        to={cardHref}
        onClick={() => {
          scrollToTop();
        }}
      >
        <div className="flex flex-col md:flex-row gap-4 md:space-y-4 mx-6 justify-center p-6 my-2 items-center bg-black bg-opacity-40 h-fit rounded-xl leading-relaxed hover:shadow-flame-pea-400 hover:shadow-md  hover:scale-105 transition-all duration-150 group text-center">
          <div
            className={
              leftImg
                ? "md:order-first my-auto md:py-4 md:pr-4 p-4 md:w-4/5 h-fit"
                : "md:order-last my-auto md:py-4 md:pl-4 p-4 md:w-4/5 h-fit"
            }
          >
            <h4 className="py-2 mb-2 text-2xl text-flame-pea-100  group-hover:text-flame-pea-500 transition-all duration-200 group-hover:scale-105 ">
              {titleText}
            </h4>
            <p className="">{cardText}</p>
          </div>
          <div className="md:col-span-1  m-auto pt-4 md:pt-0 md:w-[50%]">
            <img
              alt={cardImgAlt}
              src={img}
              className="rounded-3xl my-auto "
            ></img>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default SideCard;
