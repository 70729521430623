export const backOfficePage = {
  title: "Managed and Supported Services",
  intro:
    "The Managed and Supported Services levels at In-House Logistics are designed to alleviate many of the administrative burdens of your logistics operations. Allowing you to focus on the principal activities that grow your business! With our dedicated team overseeing crucial tasks you can experience streamlined processes, improved efficiency, and decreased costs!",
  keyPoints: [
    {
      title: "Managed Transportation Services",
      description:
        "The comprehensive Managed Transportation Service that will allow you to utilize the same robust functionality and work flow multi-national shippers use! Our Managed Transportation Services are available in a cost plus environment which allow you to delegate non-core business activities currently handled by your team to your new In-House partners! Help protect yourself from high tender rejection rates and market price volatility by installing a long term plan with In-House Logistics!",
      image: "transportationServices.webp",
      imageAlt: "Office  work being performed",
    },
    {
      title: "Supported Transportation Services",
      description:
        "Our Supported Transportation Services are available in a contract environment. Our comprehensive Supported Transportation Service will implement cost and performance based contract agreements with underlying carriers in order to insulate you from changing market dynamics. Driving steadier tender acceptance rates by agreeing to contract freight allocations with stable, forecastable rates.",
      image: "managedTransportation.webp",
      imageAlt: "Office  work being performed",
    },
    {
      title: "Cost Savings",
      description:
        "Enjoy the cost savings and profit center creation you experience with our previous service offerings but do it in scale! Increasing volume and commitment levels will multiply savings and continue to build your newly created internal freight profit center.",
      image: "savings2.webp",
      imageAlt: "Office  work being performed",
    },
    {
      title: "Back Office Support",
      description:
        "We help our clients by reducing costs and increasing operational efficiencies. Based on your needs, In-House Logistics will engineer a strategy for recruitment, hiring, training, and retention processes to ensure you optimize the workflow within your team. Utilizing back office support with In-House Logistics will streamline your employees duties so they can focus on what is most important to your business!",
      image: "officeSupport.webp",
      imageAlt: "Office  work being performed",
    },
    {
      title: "Risk Management",
      description:
        "The Supported Transportation Services Level at In-House Logistics has the ability to provide protection from the liability and risks of transporting cargo your company incurs. We handle claims management, claims payments, processing, and the insurance verification processes that are so critical to safely and soundly operating within this current litigious transportation environment.",
      image: "risk.webp",
      imageAlt: "Office  work being performed",
    },
    
    {
      title: "Carrier Sourcing, Procurement, and Management ",
      description:
        " The Managed and Supported Services Levels at In-House Logistics will allow you direct access to our ever-expanding internal carrier base. Allowing you to increase market capacity, ensure carrier compliance, and negotiate from a stronger position. In-House Logistics can act as your internal provider assisting the RFP, RFQ, project based, and spot rate gathering activities, as well as tendering and allocation strategies.",
      image: "trucker5.webp",
      imageAlt: "",
    },
    
    {
      title: "Freight Audit and Pay",
      description:
        "As domestic and global supply chains increase in complexity it is critical to have strong oversight process in both equipment and accessorial usage. In-House Logistics can increase automation to help avoid supply chain disruptions which will help minimize costs while simultaneously acting as an oversight valve to guarantee validity of billables. Ensure fees billed such as detention, stop-offs, lumpers, layovers, tonus and gate fees are accurate and documented!",
      image: "accounting2.webp",
      imageAlt: "",
    },
    {
      title: "Document Management and Record Keeping",
      description:
        "Shippers and Receivers are required to manage a multitude of documents for goods moved within their supply chains and it is imperative that every piece of documentation is readily available and accessible when you need it most. By partnering with In-House Logistics, you can ensure accurate and easily accessible documentation for every step of your logistics process!",
      image: "files.webp",
      imageAlt: "",
    },

  ],
  outroOne:
    "When partnering with In-House Logistics at the Managed and Supported Services levels you will reduce the burden of many administrative duties that are currently weighing down your logistics team.",
    outroTwo:" Enjoy the benefits of accurate accounting, efficient claims management, streamlined carrier setup, contract negotiations, and other crucial back office support, allowing you to focus on growing your business and delivering exceptional service to your customers."
};

