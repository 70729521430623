import React from "react";
import { Link } from "react-router-dom";
import { ImBubbles4 } from "react-icons/im";
import { AiOutlineDatabase } from "react-icons/ai";
import { HiOutlineBuildingOffice2 } from "react-icons/hi2";
import { CiDeliveryTruck } from "react-icons/ci";
import { scrollToTop } from "../components/ScrollToTop";

const ServiceCard = (props) => {
  // console.log(props);
  const title = props.title;
  const description = props.description;
  const webLink = String(props.href);

  function findIcon(icon) {
    switch (icon) {
      case "ImBubbles4":
        return (
          <ImBubbles4
            size="5rem"
            className="mx-auto group-hover:text-flame-pea-500 transition-all duration-150"
          />
        );
      case "AiOutlineDatabase":
        return (
          <AiOutlineDatabase
            size="5rem"
            className="mx-auto group-hover:text-flame-pea-500 transition-all duration-150"
          />
        );
      case "HiOutlineBuildingOffice2":
        return (
          <HiOutlineBuildingOffice2
            size="5rem"
            className="mx-auto group-hover:text-flame-pea-500 transition-all duration-150"
          />
        );
      case "CiDeliveryTruck":
        return (
          <CiDeliveryTruck
            size="5rem"
            className="mx-auto group-hover:text-flame-pea-500 transition-all duration-150"
          />
        );
      default:
        return;
    }
  }

  return (
    <>
      <Link to={webLink} className="" onClick={()=>scrollToTop()}>
        <div className="relative text-center border border-spacing-10 border-white border-opacity-30 bg-black bg-opacity-25 p-4 h-fit md:h-[450px] lg:h-[575px] xl:h-[500px] w-4/5 lg:w-fit xl:w-72 mx-auto my-2 rounded-xl hover:shadow-flame-pea-400 hover:shadow-md  hover:scale-110 group transition-all duration-150 ">
          {findIcon(props.icon)}
          <h3 className="py-4 underline underline-offset-4 text-xl ">
            {title}
          </h3>
          <p className=" mb-10">{description}</p>

          <p className="absolute bottom-2 inset-x-0 group-hover:underline underline-offset-4 group-hover:text-flame-pea-500 transition-all duration-150">
            Learn More
          </p>
        </div>
      </Link>
    </>
  );
};

export default ServiceCard;
