import React, { useEffect, useState } from "react";
import HerouselSlide from "./HerouselSlide";

const Herousel = (props) => {
  const slideArray = props.array;
  const finalSlide = slideArray.length - 1;
  const [slide, setSlide] = useState(slideArray[0]);
  const [nextSlide, setNextSlide] = useState(slideArray[1]);
  const [slideCount, setSlideCount] = useState(0);

  const slideStyle =
    "w-full h-[75vh] overflow-hidden mx-auto opacity-100   transition-all  duration-250 absolute top-0 object-cover  scale-100 ease-in-out";
  const nextSlideStyle =
    "w-full h-[75vh] overflow-hidden mx-auto opacity-0 transition-all duration-500 absolute top-0 object-cover scale-0 ease-in-out";

  useEffect(() => {
    let i = slide.key;
    setSlideCount(slide.key);
    setTimeout(() => {
      if (i !== finalSlide && i !== 0) {
        setNextSlide(slideArray[i + 1]);
      } else if (i === finalSlide) {
        setNextSlide(slideArray[0]);
      } else if (i === 0) {
        setNextSlide(slideArray[i + 1]);
      }

      // console.log(
      //   "current Slide index = " +
      //     slide.key +
      //     ", |next slide index = " +
      //     nextSlide.key
      // );
    }, 6000);
  });

  useEffect(() => {
    setSlide(nextSlide);
  }, [nextSlide]);

  return (
    <div className="h-[50vh]">
      {slideArray.map((sld, index) => {
        return (
          <HerouselSlide
            key={sld.key}
            slideName={sld.name}
            slideImage={sld.image}
            slideImgAlt={sld.imageAlt}
            slideId={sld.slideId}
            slideStyle={slideCount === index ? slideStyle : nextSlideStyle}
          />
        );
      })}
    </div>
  );
};

export default Herousel;
