import React from "react";
import { techPage } from "../../pageText/services/technology";
import SubServiceBody from "./SubServiceBody";

const Technology = () => {
  
  return (
    <div className="">
      <section id="technologyHead">
        <div className="bg-techPattern h-48 md:h-96 text-flame-pea-100 flex justify-center items-center lg:bg-no-repeat bg-center bg-cover md:bg-cover relative">
          <h1 className="absolute top-3 md:top-10 lg:top-8 xl:top-3 text-2xl md:text-5xl ring-1 ring-flame-pea-100 p-1 md:ring-offset-2 md:p-2 rounded-md text-center">
            {techPage.title}
          </h1>
        </div>
      </section>
      <div className="p-2">
        <div
          id="techIntro"
          className="flex flex-col justify-center w-[90%] md:w-3/5 text-center mx-auto p-4 ring-2 ring-white ring-opacity-20 rounded-xl m-12 md:ring-0"
        >
          <p className="mx-auto text-xl">{techPage.intro}</p>
        </div>
        <section id="techBody" >
          <SubServiceBody content={techPage} />
        </section>
        <div
          id="techOutro"
          className="flex flex-col justify-center w-[90%] md:w-3/5 text-center mx-auto p-4 ring-2 ring-white ring-opacity-20 rounded-xl m-12 md:ring-0"
        >
          <p className=" mx-auto text-xl">{techPage.outro}</p>
          <p className=" mx-auto text-xl pt-4">{techPage.outroTwo}</p>
        </div>
      </div>
    </div>
  );
};

export default Technology;
