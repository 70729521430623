// CONSULTATION Sub=Page

export const consultPage = {
  title: "Consulting",
  intro:
    "Our Consultation offering is designed to provide strategic guidance and industry insights to help you optimize your logistics operations. With our extensive industry knowledge and experience, we offer tailored solutions to address your unique challenges and drive your business forward.",
    keyPoints: [
    {
      title: "Strategy Development",
      description:
        "We work closely with your team to understand your business goals and develop a comprehensive logistics strategy aligned with your objectives. Our expertise enables us to identify opportunities for improvement and recommend strategies to enhance efficiency and competitiveness.",
      image: "managed3.webp",
      imageAlt: "On going consultation",
    },
    {
      title: "Process Optimization",
      description:
        "We conduct in-depth analysis of your existing logistics processes to identify bottlenecks, inefficiencies, and areas for improvement. Through our consultation services we help streamline processes, eliminate redundancies, and implement best practices to enhance operational efficiency.",
      image: "optimization.webp",
      imageAlt: "On going consultation",
    },
    
    {
      title: "Technology Implementation",
      description:
        "We assess your technology infrastructure and recommend solutions that align with your business needs. From selecting the correct transportation management system to implementing warehouse automation, our consultation expertise ensures you leverage the right technology for improved efficiency and visibility.",
      image: "broker2.webp",
      imageAlt: "On going consultation",
    },
    {
      title: "Performance Measurement and Analytics",
      description:
        "We help you establish key performance indicators (KPIs) and develop measurement frameworks to track and evaluate your logistics performance. Through data analytics we provide actionable insights to drive continuous improvement and enhance decision making.",
      image: "consult5.webp",
      imageAlt: "On going consultation",
    },
    {
      title: "Change Management and Training",
      description:
        "Implementing new strategies and technologies requires effective change management. We support your organization through the process, providing training programs and guidance to ensure smooth adoption and successful implementation.",
      image: "instruct1.webp",
      imageAlt: "On going consultation",
    },
  ],
  outro: "",
};
