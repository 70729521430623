import React, { useState } from "react";
import TWAccordionCard from "./TWAccordionCard";

export default function TWAboutAccordion(props) {
  const [activeElement, setActiveElement] = useState("");

  const handleClick = (value) => {
    if (value === activeElement) {
      setActiveElement("");
    } else {
      setActiveElement(value);
    }
  };
  return (
    <>
      <div
        id="accordionExample"
        className="w-[75vw] md:w-3/5 mx-auto border border-spacing-x-2 border-flame-pea-50"
      >
        {props.elements.map((element, i) => {
          return (
            <TWAccordionCard
              key={i}
              handleClick={handleClick}
              activeElement={activeElement}
              element={element}
            />
          );
        })}
      </div>
    </>
  );
}
