import React, { useState } from "react";
import axios from "axios";
import LoadingModal from "../elements/LoadingModal";

const Contact = () => {
  const [loadingModal, setLoadingModal] = useState(false);
  function formatPhoneNumber(e) {
    let phoneNumber = e.target.value.replace(/\D/g, '');
    if (phoneNumber.length === 10) {
      phoneNumber = phoneNumber.match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      phoneNumber = `(${phoneNumber[1]}) ${phoneNumber[2]}-${phoneNumber[3]}`;
      e.target.value = phoneNumber;
    } else {
      e.target.value = phoneNumber;
    }
  }

  function handleSubmit(e) {
    e.preventDefault();
    setLoadingModal(true);
    const formData = new FormData(e.target.form);
    const formProps = Object.fromEntries(formData);

    // console.log("this is the formData", e.target.form);
    // console.log("these are the formProps", formProps);

    // Send a POST request to your PHP script
    axios
      .post("https://in-houselogistics.com/form_email.php", formProps)
      .then((response) => {
        console.log(response);
        window.location.href="https://in-houselogistics.com";
      })
      .catch((error) => {
        console.error(error);
      });
  }

  return (
    <div>
    {loadingModal? <LoadingModal/>:null}
      <div
        id="Contact"
        className="flex flex-wrap items-center justify-evenly h-fit p-12 w-fit mx-auto max-w-6xl"
      >
        <div className="p-8 md:max-w-[40%] space-y-2">
          <h3 className="font-bold text-lg">Why Use In-House</h3>
          <p>
            We understand that each client is unique with specific needs and
            goals. We take the time to listen, analyze, and develop customized
            solutions that address the individual challenges faced by our
            clients. With multiple Service Offerings we have a solution that
            will fit your current and future needs.
          </p>
          <p>
            Come join the ranks of our many successful clients and start turning
            your freight needs into freight profits!
          </p>
        </div>
        <div className="max-w-sm ">
          <form
            name="contactForm"
            id="contactForm"
            action=""
            method="POST"
            className="bg-gray shadow-md rounded px-8 py-6 space-y-6 focus-within:ring-1 focus-within:ring-gray"
          >
            <div className="flex justify-around space-x-4">
              <div>
                <label
                  className="block text-gray-400 text-sm font-bold mb-2"
                  for="firstName"
                >
                  First Name
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline bg-stone-50 focus-within:bg-white focus-within:text-black"
                  id="fName"
                  name="fName"
                  type="text"
                  placeholder="First Name"
                  required
                ></input>
              </div>
              <div>
                <label
                  className="block text-gray-400 text-sm font-bold mb-2"
                  for="lastName"
                >
                  Last Name
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline  bg-stone-50 focus-within:bg-white focus-within:text-black"
                  id="lName"
                  name="lName"
                  type="text"
                  placeholder="Last Name"
                  required
                ></input>
              </div>
            </div>
            <div>
              <label
                className="block text-gray-400 text-sm font-bold mb-2"
                for="email"
              >
                Email
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline  bg-stone-50 focus-within:bg-white focus-within:text-black"
                id="email"
                name="email"
                type="email"
                placeholder="Enter Your Email"
                required
              ></input>
            </div>
            <div>
              <label
                className="block text-gray-400 text-sm font-bold mb-2"
                for="phoneNumber"
              >
                Phone
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline  bg-stone-50 focus-within:bg-white focus-within:text-black"
                id="pNumber"
                name="pNumber"
                type="tel"
                placeholder="Phone Number"
                maxLength={14}
                onChange={(e) => formatPhoneNumber(e)}
                required
              ></input>
            </div>
            <div>
              <label
                className="block text-gray-400 text-sm font-bold mb-2"
                for="message"
              >
                Message
              </label>
              <textarea
                className="shadow appearance-none border rounded w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline  bg-stone-50 focus-within:bg-white focus-within:text-black"
                id="message"
                name="message"
                rows="4"
                placeholder="Your Message Here"
              ></textarea>
            </div>

            <input
              className="bg-gray hover:bg-flame-pea-600 text-white font-bold mx-auto py-2 px-4 rounded focus:outline-none focus:shadow-outline focus:shadow-inner"
              type="submit"
              value="Submit"
              onClick={(e) => {
                handleSubmit(e);
              }}
            ></input>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;
