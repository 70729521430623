import React from "react";
import {
  FaFacebookSquare,
  FaInstagram,
  FaTwitterSquare,
  FaCopyright,
} from "react-icons/fa";
import { callToAction } from "../pageText/home";
import inHouseLogo from "../assets/logoReworked.svg";

let date = new Date();
let year = date.getFullYear();

const Footer = () => {
  return (
    <div>
      <section id="callToAction" className="mx-auto lg:hidden">
        <div className="flex flex-col mx-auto justify-center">
          <h4 className="mx-auto text-2xl px-6 md:w-1/2 text-center">
            {callToAction.mainBody}
          </h4>
          <button className="w-fit mx-auto my-6 rounded-xl bg-flame-pea-800 p-2 bg-opacity-70 text-xl active:ring-1 active:ring-flame-pea-400 hover:bg-opacity-80 hover:scale-105 active:bg-flame-pea-900 active:bg-opacity-100">
            <a href="/contact">
              <div className=" focus:bg-flame-pea-900 focus:bg-opacity-100">
                Contact Us Today!
              </div>
            </a>
          </button>
        </div>
      </section>
      <footer className="bottom-0 h-fit w-fit md:max-w-[80%] mt-28 p-6 mx-auto">
        <div className="flex justify-evenly items-center">
          <div className="flex-shrink-0 w-fit justify-center">
            <a href="/">
              <img
                className=" w-44 md:w-64 drop-shadow-md mx-auto"
                alt="Inhouse Logistics Logo"
                src={inHouseLogo}
              ></img>
              <p className="hidden md:inline-block mx-auto text-center text-xs flex-shrink">
                Your Consultative Provider of In-House Brokerage Services
              </p>
            </a>
          </div>
          <section id="callToAction" className="mx-auto hidden lg:block">
            <div className="flex flex-col mx-auto justify-center">
              <h4 className="mx-auto text-2xl px-6 md:w-1/2 text-center">
                {callToAction.mainBody}
              </h4>
              <button className="w-fit mx-auto my-6 rounded-xl bg-flame-pea-800 p-2 bg-opacity-70 text-xl active:ring-1 active:ring-flame-pea-400 hover:bg-opacity-80 hover:scale-105 active:bg-flame-pea-900 active:bg-opacity-100">
                <a href="/contact">
                  <div className=" focus:bg-flame-pea-900 focus:bg-opacity-100">
                    Contact Us Today!
                  </div>
                </a>
              </button>
            </div>
          </section>

          <div className="right-0 pr-4">
            <h3 className="pt-2 m-auto text-center text-lg md:text-2xl font-semibold whitespace-nowrap">
              In-House
              <span className="text-flame-pea-500 font-black"> Logistics</span>
            </h3>
            <p className="mx-auto text-center py-1 text-xl">312-741-1772</p>
            <div className="flex mx-auto justify-between w-4/5 my-2">
              <FaFacebookSquare size={30} />
              <FaInstagram size={30} />
              <FaTwitterSquare size={30} />
            </div>
          </div>
        </div>
        <div className="flex pb-3 opacity-75 mt-4 justify-center mx-auto w-[82vw]">
          <FaCopyright />
          <h6 className="pl-2  font-light">
            {year}, In-House
            <span className="text-flame-pea-500 font-bold"> Logistics </span>
          </h6>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
