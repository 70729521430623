import React from "react";

import happyDriver from "../assets/trucker4.webp";
import sunsetTruck from "../assets/sunsetTruck.webp"
import deskShake from "../assets/deskShake.webp";
import { aboutUs, faqSection } from "../pageText/about";
import AboutAccordion from "../elements/AboutAccordion";
import TWAboutAccordion from "../elements/TWAboutAccordion";
import ThreeStack from "../elements/ThreeStack";
function About() {
  return (
    <>
      <section id="AboutHead">
        <div className="bg-truckRoad h-48 md:h-96 text-flame-pea-100 flex justify-center items-center lg:bg-no-repeat bg-center bg-cover md:bg-cover relative">
          <h1 className="absolute bottom-3 md:bottom-10 lg:bottom-8 xl:bottom-3 text-3xl md:text-5xl ring-1 ring-flame-pea-100 p-1 md:ring-offset-2 md:p-2 rounded-md">
            {aboutUs.pageTitle}
          </h1>
        </div>
      </section>

      <section className="" id="ProcessBody">
        <div className="flex flex-col lg:flex-row overflow-hidden items-center my-12">
          <div className="lg:w-[50vw] max-w-[75vw] p-4 lg:p-8 rounded-2xl overflow-hidden">
            <img
              src={deskShake}
              alt="executives shaking hands"
              className="rounded-2xl"
            ></img>
          </div>
          <div className="flex flex-col justify-center items-center mt-4 lg:mt-0 mx-auto w-[75vw] lg:w-3/5">
            <h2 className="text-2xl text-center py-2 lg:py-6 font-bold">
              {aboutUs.topSectionTitle}
            </h2>
            <p className="w-auto lg:px-24 lg:text-center">
              {aboutUs.topSectionBody}
            </p>
          </div>
        </div>
      </section>

<section className="mx-auto my-24" id="vissionMission">
   <ThreeStack cards={aboutUs.visionMission}/>
</section>
     

      {/* <section className="" id="AboutWhy">
        <div className="flex flex-col justify-center items-center my-12 py-4 mx-auto w-3/5">
          <div>
            <h2 className="text-xl text-center py-6 font-bold">
              {aboutUs.whySectionTitle}
            </h2>
            <p className="py-2 text-center">{aboutUs.whySectionBody}</p>
            <p className="py-2 text-center">{aboutUs.whySectionBody2}</p>
          </div>
        </div>
      </section> */}

      <section className="" id="ProcessBody">
        <div className="flex flex-col lg:flex-row-reverse overflow-hidden items-center my-12">
          <div className="lg:w-[50vw] max-w-[75vw] p-2 lg:p-8 rounded-2xl overflow-hidden">
            <img
              src={sunsetTruck}
              alt="Truck driving at sunset"
              className="rounded-2xl"
            ></img>
          </div>
          <div className="flex flex-col justify-center items-center mt-4 lg:mt-0 mx-auto w-[75vw] lg:w-3/5">
            <h2 className="text-2xl text-center py-2 lg:py-6 font-bold">
              {aboutUs.processSectionsTitle}
            </h2>
            <p className="w-auto lg:px-24 lg:text-center">
              {aboutUs.processSectionBody}
            </p>
          </div>
        </div>
      </section>

      <div className="h-24 my-12 flex items-center">
        <h3 className="text-center text-4xl m-auto font-bold">
          Frequently Asked Questions
        </h3>
      </div>

      <section className="mb-8">
        <TWAboutAccordion elements={faqSection} />
      </section>
    </>
  );
}

export default About;
