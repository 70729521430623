export const initialOfferPage = {
  title: "Initial Service Offering",
  intro:
    "The structure of our Initial Service Offering is such that when you have a shipment that is moved by our internal team you will receive 20% of Gross Profits returned to you on a quarterly basis. Immediately turning your current freight needs into a newly realized profit center. Our team of experts will act to seamlessly integrate your business needs and become a true extension of your logistics department.",
  keyPoints: [
    {
      title: "Cost Savings",
      description:
        "Our experts will identify opportunities for cost optimization and price reduction throughout your supply chain. By leveraging our industry knowledge and strategic partnerships we will help reduce transportation costs, streamline processes, and eliminate inefficiencies.",
      image: "savings.webp",
      imageAlt: "Man in suit with hard hat",
    },
    {
      title: "Pricing Transparency",
      description:
        "With the In-House Logistics Initial Service Offering you will receive complete pricing visibility into your logistics costs reported back to you on a quarterly basis. Immediately allowing you to negotiate from a stronger position with your current shipping vendors.",
      image: "managed1.webp",
      imageAlt: "Man in suit with hard hat",
    },

    {
      title: "Enhanced Efficiency",
      description:
        "With our Initial Service Offering, you can expect improved operational efficiency, reduced lead times, and reduced freight rates. Our team will leverage advanced technology, such as automated order processing and route optimization to streamline your logistics processes and ensure timely delivery of your goods.",
      image: "gears.webp",
      imageAlt: "Man in suit with hard hat",
    },

    {
      title: "Scalability and Flexibility",
      description:
        "As your business evolves, our Initial Service Offering can easily scale and progress to our next service offering to accommodate your changing needs. Whether you are expanding into new markets, experiencing seasonal fluctuations, or launching new products, we will adapt our services to support your growth.",
      image: "expand.webp",
      imageAlt: "Man in suit with hard hat",
    },
  ],
  outro:
    "By partnering with In-House Logistics at the Initial Service Offering level you can reduce the complexities of logistics vendor management and load tendering to a trusted partner. Allowing you to focus on your core business while enjoying full transparency and control over your supply chain. Begin harnessing the profitability of your shipments by partnering with the In-House Logistics team today!",
};


