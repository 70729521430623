import React from "react";

import { servicesText } from "../pageText/serviceMain";

import SideCard from "../elements/SideCard";

const Services = () => {

  return (
    <div className="">
      <section id="serviceHead">
        <div className="bg-trucksDocked h-48 md:h-96 text-flame-pea-100 flex justify-center items-center lg:bg-no-repeat bg-center bg-cover md:bg-cover relative">
          <h1 className="absolute top-3 md:top-10 lg:top-8 xl:top-3 text-2xl md:text-5xl ring-1 ring-flame-pea-100 p-1 md:ring-offset-2 md:p-2 rounded-md text-center tracking-wider">
            {servicesText.servicePageTitle}
          </h1>
        </div>
      </section>
      <div
        id="serviceIntro"
        className="flex flex-col md:w-3/4 mt-16 mx-auto text-center py-6 px-10"
      >
        <p>{servicesText.serviceHeroText}</p>
      </div>

      <div className="flex flex-col py-10 lg:w-4/5  justify-center mx-auto gap-y-8 px-6">
        {servicesText.serviceArray.map((service, i) => {
          let leftImg = false
          if(i%2 === 0) {leftImg = true}
          return (
            <SideCard
              cardText={service.description}
              cardImg={service.image}
              titleText={service.name}
              cardImgAlt={service.imageAlt}
              leftImg={leftImg}
              cardHref={service.href}
              key={service.slideId}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Services;
