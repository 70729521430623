export const servicesText = {
  servicePageTitle: "Build the right package for you",
  serviceHeroText:
    "We offer a comprehensive suite of solutions designed to empower and improve your logistics operations. Whether you are interested in exploring our services on a load-by-load basis or are seeking a full consultation, at In-House Logistics we are committed to optimizing your supply chain and driving your business forward. Explore each of our service offerings below and discover how we can tailor our expertise to meet your unique needs!",
  consultationTitle: "Consultation",
  consultationBody:
    "Let us help find weaknesses in your current transportation implementation.",
  technologyTitle: "Technology",
  technologyBody:
    "Access to industry leading technology is expensive, Learn how we can help modernize your workflow and save you money.",
  managedServicesTitle: "Managed Services",
  managedServicesBody:
    "Hiring and Training a transportation team is made easy with In-House Logistics. Learn how our expertise can help you today.",
  backOfficeTitle: "Back Office Services",
  backOfficeBody:
    "Let us handle the headache of administrative operations. Read more to see what we can do for you.",
  serviceArray: [
    {
      name: "Initial Service Offering",
      slideId: "initialServiceOffering",
      description:
        "A risk-free way to turn your freight into a profit center!  Allow us to act as your In-House Logistics provider where our goal is to increase your profitability and reduce freight rates.",
      icon: "CiDeliveryTruck",
      href: "/services/initialOffer",
      image: "managed2.webp",
      imageAlt: "",
    },
    {
      name: "Technology",
      slideId: "technology",
      description:
        " Our cutting-edge technology solutions empower businesses with innovative tools, real-time visibility, and seamless integration. Enabling our clients to streamline their logistics processes and make informed, data-driven decisions.",
      icon: "AiOutlineDatabase",
      href: "/services/technology",
      image: "tech2.webp",
      imageAlt: "Circuit Board",
    },

    {
      name: "Managed and Supported Services",
      slideId: "backOffice",
      description:
        " We will craft a service to support your business on site or remotely. We allow our customers to focus on their core business while they allow us to support their logistics needs.",
      icon: "HiOutlineBuildingOffice2",
      href: "/services/backOffice",
      image: "office1.webp",
      imageAlt: "",
    },
    {
      name: "Consulting",
      slideId: "consultation",
      description:
        " Our expert consultants provide strategic guidance and tailored solutions to help small to medium-sized shippers optimize their logistics operations, reduce costs, and enhance efficiency.",
      icon: "ImBubbles4",
      href: "/services/consulting",
      image: "consult.webp",
      imageAlt: "",
    },
  ],
};
