import React from 'react'

const LoadingModal = () => {
  return (
    <div className="fixed top-0 left-0 w-full h-screen flex items-center justify-center z-50 bg-black bg-opacity-50">
    {/* Spinner */}
    <div className="animate-spin border-t-4 border-blue-500 border-solid rounded-full h-12 w-12"></div>
  </div>
  )
}

export default LoadingModal