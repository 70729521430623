// Home Page
export const heroText =
  "We empower shippers like you to transform your supply chain, reduce operational costs, and enhance customer satisfaction.";

export const callToAction = {
  mainBody: " Contact us today to unlock the power of streamlined logistics!",
};

export const homePageIntro = {
  body1:
    "At In-House Logistics we offer a comprehensive suite of solutions designed to empower your logistics operation. Our offerings range from a risk-free Initial Service Offering, cutting-edge Technology Integration, Managed and Supported Service Offerings, to a full Consultative solution. We are committed to optimizing your supply chain and driving your business forward.",
  body2:
    "Explore our range of services below and discover how we can tailor our expertise to meet your unique logistics needs!",
};
export const serviceCardText = [
  {
    title: "Initial Service Offering",
    description:
      "Our Initial Service Offering allows for our clients to immediately turn their freight into a newly realized profit center. We achieve this by offering 20% Gross Profit Reimbursement on any shipment moved with our internal team! ",
    icon: "CiDeliveryTruck",
    href: "/services/initialOffer",
  },
  {
    title: "Technology",
    description:
      "Our cutting-edge technology solutions empower businesses with innovative tools, real-time visibility, and seamless integration. Enabling our clients to streamline their logistics processes and make informed, data-driven decisions.",
    icon: "AiOutlineDatabase",
    href: "/services/technology",
  },
  {
    title: "Managed and Supported Services",
    description:
      "We will craft a service to support your business on site or remotely. We allow our customers to focus on their core business while they allow us to support their logistics needs. ",
    icon: "HiOutlineBuildingOffice2",
    href: "/services/backoffice",
  },
  {
    title: "Consulting",
    description:
      "Our expert consultants provide strategic guidance and tailored solutions to help small to medium-sized shippers optimize their logistics operations, reduce costs, and enhance efficiency.",
    icon: "ImBubbles4",
    href: "/services/consulting",
  },
];
