import React from "react";
import SubServiceCard from "./SubServiceCard";

const SubServiceBody = (props) => {
  const content = props.content;

  return (
    <div className="flex flex-col justify-center mx-auto space-y-10">
      {content.keyPoints.map((keyPoint, i) => {
        return (
          <SubServiceCard
            title={keyPoint.title}
            desc={keyPoint.description}
            image={keyPoint.image}
            imageAlt={keyPoint.imageAlt}
            index={i}
            key={i}
          />
        );
      })}
    </div>
  );
};

export default SubServiceBody;
