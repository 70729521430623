import Footer from './components/Footer';
import NavBar from './components/NavBar';
import InHouseRoutes from './components/routes';

function App() {
  return (
    <div className="">
    <NavBar/>
    <InHouseRoutes  />
    <Footer />
    </div>
  );
}

export default App;
