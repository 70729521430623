export const techPage = {
  title: "Industry Leading Technology",
  intro:
    "Our Technology Offering provides access to our industry leading Transportation Management Systems (TMS) without the need of taking on the sizable and immediate cost outlay and time commitment for an independent subscription. You will have the ability to access key performance indicators instantaneously such as pricing, shipment status, locations, and other vital information throughout our intuitive platform.",
  keyPoints: [
    {
      title: "Pricing Transparency",
      description:
        "At the In-House Logistics Technology Offering level you will receive instant visibility to your underlying logistics costs on a load by load basis! Start operating your logistics team as a profit center and capture margins that are currently exiting your firm.",
      image: "techLaptop.webp",
      imageAlt: "circuit board",
    },
    {
      title: "Real-Time Tracking and Visibility",
      description:
        "Everyone knows in transit visibility to shipment locations are vital, especially in limited shelf life commodities. Increase efficiency and customer service by having direct and real-time visibility on the locations of your shipments with ease by utilizing the In-House Logistics Technology Offering today!",
      image: "smartphoneTracking.webp",
      imageAlt: "circuit board",
    },
    {
      title: "Reporting",
      description:
        "Utilize In-House Logistics software to open the door to fully optimized reporting! Our Technology Offering will empower you to collect data generated from customized reporting tools that align with your business needs. Receive timely, relevant, and actionable data that you can analyze to immediately implement logistical improvements. ",
      image: "reporting.webp",
      imageAlt: "graph on a tablet",
    },
    {
      title: "Carrier Management and Collaboration",
      description:
        "The In-House Logistics Technology Offering will improve your ability to effectively manage your carrier relationships. With the In-House Technology Offering you will receive immediate visibility to your comprehensive carrier history, tracking performance metrics, pricing, and more!",
      image: "carrierCollab.webp",
      imageAlt: "circuit board",
    },
    {
      title: "Document Management and Record Keeping",
      description:
        "By utilizing the Technology Offering at In-House Logistics you guarantee all shipping and delivery documentation be created, stored, and instantaneously made available to you when and wherever you need it, cutting both time and costs from your current day to day operations!",
      image: "documentMgmt.webp",
      imageAlt: "Secure Storage Server",
    },
    {
      title: "Data Driven Decision Making",
      description:
        "Harness the power of data analytics within our Industry Leading In-House Logistics Technology Offering! Our platform will allow you to make informed data-driven decisions. Allowing you to better understand where your freight spend is going and affording you the opportunity to identify areas to lower costs and increase service.",
      image: "dataDriven.webp",
      imageAlt: "circuit board",
    },
    {
      title: "Seamless Integration and Support",
      description:
        "Our team will handle the setup, configuration, and integration of your new technology suite into your existing operations to ensure a smooth onboard and transition. We provide training and ongoing support to maximize your utilization of the platform to achieve optimal results!",
      image: "integrationSupport.webp",
      imageAlt: "circuit board",
    },
  ],
  outro:
    "By leveraging the In-House Logistics Technology Offering, you can optimize your transportation operations, receive instant pricing visibility, enhance overall efficiency, and improve customer service.",
  outroTwo:"Benefit from industry-leading technology without the burden of a full subscription cost and experience the transformative impact technology can have on your logistics operations!",
};

