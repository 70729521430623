import React from "react";
import ServiceCard from "../elements/ServiceCard";
import {
  callToAction,
  heroText,
  homePageIntro,
  serviceCardText,
} from "../pageText/home";
import { FaPhone } from "react-icons/fa";
import Herousel from "../elements/Herousel";
import { homeHeroImages } from "../pageText/herosouel";

function Home() {
  return (
    <div>
      <div className="relative h-[75vh]">
        <Herousel array={homeHeroImages} />
        <div className="absolute flex flex-col h-full w-full md:w-1/2 justify-center top-0 z-20 p-6  text-lg m-auto bg-black bg-opacity-70">
          <p className="text-center md:text-xl lg:text-2xl items-center mx-auto font-semibold drop-shadow-2xl overflow-hidden">
            {heroText}
          </p>
          <div className="flex flex-col mt-12 mx-auto">
            <a href="/contact" className="">
              <FaPhone
                name="contact"
                size={"3rem"}
                className="mx-auto text-white mb-6"
              />
              <p className="font-extrabold text-2xl md:text-3xl lg:text-4xl">
                Contact Us
                <span className="text-flame-pea-500 font-black"> TODAY</span>
              </p>
            </a>
          </div>
        </div>
      </div>

      <section id="introSection" className="mx-auto flex flex-col">
        <div className="md:w-3/4 mx-auto mt-10 flex flex-col gap-y-6 p-16 text-lg text-center">
          <p className="">{homePageIntro.body1}</p> <p>{homePageIntro.body2}</p>
        </div>
      </section>

      {/* Services Summary */}
      <section id="servicesSummary">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-2 lg:grid-cols-4 lg:gap-4 mx-auto mt-20 pb-24 px-10">
          {serviceCardText.map((card) => {
            return (
              <ServiceCard
                className=""
                title={card.title}
                description={card.description}
                icon={card.icon}
                href={card.href}
              />
            );
          })}
        </div>
      </section>

      {/* Call To Action 
      <section id="callToAction" className="mx-auto">
        <div className="flex flex-col mx-auto justify-center">
          <h4 className="mx-auto text-2xl px-6 md:w-1/2 text-center">
            {callToAction.mainBody}
          </h4>
          <button className="w-fit mx-auto my-6 rounded-xl bg-flame-pea-800 p-2 bg-opacity-70 text-xl active:ring-1 active:ring-flame-pea-400 hover:bg-opacity-80 hover:scale-105 active:bg-flame-pea-900 active:bg-opacity-100">
            <a href="/contact">
              <div className=" focus:bg-flame-pea-900 focus:bg-opacity-100">
                Contact Us Today!
              </div>
            </a>
          </button>
        </div>
      </section> */}
    </div>
  );
}

export default Home;
