import React from "react";

const ThreeStackCard = (props) => {
  const title = props.title;
  const body = props.body;
  const isFirst = props.isFirst
  const isLast = props.isLast
  return (
    <>
      <div className="flex-col gap-y-4 max-w-[75vw] lg:w-1/3 ">
        <h4 className="font-bold mb-4 text-center text-2xl">{title}</h4>
        <p>{body}</p>
      </div>
      {
        isLast? null:      <div
        className="hidden lg:block lg:h-[350px] xl:h-[300px] min-h-[1em] w-[2px] my-auto self-stretch bg-gradient-to-tr from-transparent via-flame-pea-100 to-transparent opacity-50"
      ></div>
      }

    </>
  );
};

export default ThreeStackCard;
