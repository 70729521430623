import React, { useState, useEffect } from "react";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import inHouseLogo from "../assets/logoReworked.svg";

const Navbar = () => {
  const [nav, setNav] = useState(false);
  const [dropMenu, setDropMenu] = useState(false);

  const handleNav = () => {
    setNav(!nav);
    if (dropMenu) {
      setDropMenu(false);
    }
  };

  const handleDropMenu = () => {
    setDropMenu(!dropMenu);
  };

  // Event Listener to Close menus when clicking outside of the menus
  useEffect(() => {
    document.addEventListener("mousedown", (e) => {
      if (dropMenu && e.target.role !== "menuitem") {
        handleDropMenu();
      }
      if (
        !dropMenu &&
        e.target.role !== "menuitem" &&
        e.target.role !== "menu"
      ) {
        setNav(false);
      }
    });
  });

  return (
    <div className="p-4 z-20 h-fit">
      <div className="flex justify-evenly items-center h-fit max-w-xl md:max-w-[1240px] m-auto px-4 py-2 text-[#eae8e3] select-none ">
        <div className="w-fit">
          <a href="/">
            <img
              className="xs:w-32 w-48 drop-shadow-md mx-auto"
              alt="Ceres Logo"
              src={inHouseLogo}
            ></img>
          </a>
          <p className="hidden md:inline-block mx-auto text-center text-xs flex-shrink">
            Your Consultative Provider of In-House Brokerage Services
          </p>
        </div>

        <ul className="hidden md:flex items-center ml-auto">
          <li className="m-4 text-lg  hover:font-semibold hover:shadow-sm">
            <a href="/" role="menuitem">
              Home
            </a>
          </li>
          <li className="m-4 text-lg  hover:font-semibold hover:shadow-sm">
            <a href="/about" role="menuitem">
              About
            </a>
          </li>
          <li
            className=""
            onMouseEnter={(e) => {
              console.log(e);
              setDropMenu(true);
            }}
            onMouseLeave={(e) => {
              if (!e.target.localName === "button") {
                setTimeout(() => {
                  console.log("This is the mouseout event", e);
                  setDropMenu(false);
                }, 1500);
              }
            }}
          >
            <div className="relative inline-block text-left">
              <div>
                <button
                  type="button"
                  className="inline-flex w-full justify-center items-center gap-x-1.5  px-3 py-2 text-lg hover:font-semibold hover:shadow-sm"
                  id="menu-button"
                  aria-expanded="true"
                  aria-haspopup="true"
                >
                  <a
                    href="/services"
                    className=""
                    role="menuitem"
                    id="serviceDropDown"
                  >
                    Services
                  </a>
                  <svg
                    className={
                      dropMenu
                        ? "-mr-1 h-5 w-5 text-gray-400 rotate-180 hover:text-white"
                        : "-mr-1 h-5 w-5 text-gray-400  hover:text-white"
                    }
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                    onClick={handleDropMenu}
                    id="dropArrow"
                    role="menuitem"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </div>

              <div
                className={
                  dropMenu
                    ? "absolute left-0 z-10 w-56 top-12 origin-top-left rounded-md bg-black shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none opacity-90 transition-all ease-in-out duration-500 blur-0"
                    : "absolute  left-0  z-10 w-56 opacity-0 transition-all duration-300 blur-md hidden"
                }
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="menu-button"
                tabIndex="-1"
                id="dropMenu"
              >
                <div
                  className="py-1"
                  role="none"
                  onMouseEnter={(e) => {
                    console.log(e);
                    setDropMenu(true);
                  }}
                  onMouseLeave={(e) => {
                    setTimeout(() => {
                      setDropMenu(false);
                    }, 500);
                  }}
                >
                  <a
                    href="/services/initialOffer"
                    className="block px-4 py-2 text-md hover:font-semibold hover:shadow-sm hover:ring-1 hover:ring-flame-pea-500"
                    role="menuitem"
                    tabIndex="-1"
                    id="menu-item-2"
                  >
                    Initial Service Offer
                  </a>
                  <a
                    href="/services/technology"
                    className="block px-4 py-2 text-md hover:font-semibold hover:shadow-sm hover:ring-1 hover:ring-flame-pea-500"
                    role="menuitem"
                    tabIndex="-1"
                    id="menu-item-1"
                  >
                    Technology
                  </a>
                  <a
                    href="/services/backoffice"
                    className="block px-4 py-2 text-md hover:font-semibold hover:shadow-sm hover:ring-1 hover:ring-flame-pea-500"
                    role="menuitem"
                    tabIndex="-1"
                    id="menu-item-3"
                  >
                    Managed and Supported Services
                  </a>
                  <a
                    href="/services/consulting"
                    className=" block px-4 py-2 text-md hover:font-semibold hover:ring-1 hover:ring-flame-pea-500"
                    role="menuitem"
                    tabIndex="-1"
                    id="menu-item-0"
                  >
                    Consulting
                  </a>
                </div>
              </div>
            </div>
          </li>
          {/* <li className="m-4 text-lg  hover:font-semibold hover:shadow-sm">
            <a href="/blog" role="menuitem">
              Blog
            </a>
          </li> */}
          <li className="m-4 text-lg hover:font-semibold hover:shadow-sm">
            <a href="/contact" role="menuitem">
              Contact
            </a>
          </li>
        </ul>
        <div onClick={handleNav} className="md:hidden end-0 cursor-pointer">
          {nav ? <AiOutlineClose size={20} /> : <AiOutlineMenu size={20} />}
        </div>

        <ul
          className={
            nav
              ? "fixed md:hidden left-0 top-0 w-2/5 h-full border-r border-r-gray-900 bg-black z-30 ease-in-out duration-500"
              : "ease-in-out duration-500 fixed z-30 left-[-100%] h-full"
          }
          id="sideMenu"
          role="menu"
        >
          <h1 className="w-full text-3xl p-2 font-bold text-[#eae8e3] text-center">
            In-House{" "}
            <span className="text-flame-pea-500 font-black">Logistics </span>
          </h1>
          <li className="m-4 border-b border-gray-300 hover:font-semibold hover:shadow-sm">
            <a href="/" onClick={handleNav} role="menuitem">
              Home
            </a>
          </li>
          <li className="m-4 border-b border-gray-300 hover:font-semibold hover:shadow-sm">
            <a href="/about" onClick={handleNav} role="menuitem">
              About
            </a>
          </li>
          <li className="m-4 border-b border-gray-300 ">
            <div className="relative inline-block text-left">
              <div>
                <button
                  type="button"
                  className="inline-flex w-full justify-center items-center gap-x-1.5  "
                  id="menu-button"
                  aria-expanded="true"
                  aria-haspopup="true"
                >
                  <a
                    href="/services"
                    className="hover:font-semibold hover:shadow-sm"
                    onClick={handleNav}
                    role="menuitem"
                  >
                    Services
                  </a>
                  <svg
                    className={
                      dropMenu
                        ? "-mr-1 h-5 w-5 text-gray-400 rotate-90 hover:text-white"
                        : "-mr-1 h-5 w-5 text-gray-400 -rotate-90 hover:text-white"
                    }
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                    id="dropArrow"
                    onClick={handleDropMenu}
                    role="menuitem"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </div>

              <div
                className={
                  dropMenu
                    ? "absolute  left-24 -top-4 z-10 w-56 bg-black rounded-md  shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none opacity-90 transition-all ease-in-out duration-400 blur-0"
                    : "absolute w-56 -left-96 -top-4 z-10 opacity-0 transition-all duration-300 blur-md"
                }
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="menu-button"
                tabIndex="-1"
                id="dropMenu"
              >
                <div className="py-1" role="none">
                  <a
                    href="/services/initialOffer"
                    className="block px-4 py-2 text-md hover:font-semibold hover:shadow-sm hover:ring-1 hover:ring-gray-500"
                    role="menuitem"
                    tabIndex="-1"
                    id="menu-item-2"
                  >
                    Initial Service Offering
                  </a>
                  <a
                    href="/services/technology"
                    className="block px-4 py-2 text-md hover:font-semibold hover:shadow-sm hover:ring-1 hover:ring-gray-500"
                    role="menuitem"
                    tabIndex="-1"
                    id="menu-item-1"
                  >
                    Technology
                  </a>
                  <a
                    href="/services/backoffice"
                    className="block px-4 py-2 text-md hover:font-semibold hover:shadow-sm hover:ring-1 hover:ring-gray-500"
                    role="menuitem"
                    tabIndex="-1"
                    id="menu-item-3"
                  >
                    Managed and Supported Services
                  </a>
                  <a
                    href="/services/consulting"
                    className=" block px-4 py-2 hover:font-semibold hover:ring-1 hover:ring-gray-500"
                    role="menuitem"
                    tabIndex="-1"
                    id="menu-item-0"
                  >
                    Consulting
                  </a>
                </div>
              </div>
            </div>
          </li>
          <li className="m-4 hover:font-semibold hover:shadow-sm">
            <a href="/contact" onClick={handleNav} role="menuitem">
              Contact
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
