import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./Home";
import About from "./About";
import Services from "./Services";
import Contact from "./Contact";
import Consultation from "./services/Consultation";
import Technology from "./services/Technology";

import BackOffice from "./services/BackOffice";
import InitialOffer from "./services/InitialOffer";

function InHouseRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/services/" element={<Services />} />
      <Route path="/services/consulting" element={<Consultation />} />
      <Route path="/services/technology" element={<Technology />} />
      <Route path="/services/initialOffer" element={<InitialOffer />} />
      <Route path="/services/backoffice" element={<BackOffice />} />
      <Route path="/contact" element={<Contact />} />
    </Routes>
  );
}

export default InHouseRoutes;
